import { useRouteError } from "react-router-dom";
import { Container, Title, Text, Stack } from "@mantine/core";

export default function ErrorPage() {
  const error: any = useRouteError();

  return (
    <Container size="lg" p="xs">
      <Stack>
        <Title>Oops!</Title>
        <Text size={16}>Sorry, an unexpected error has occurred.</Text>
        <Text italic>{error.statusText || error.message}</Text>
      </Stack>
    </Container>
  );
}
