import { FC } from "react";
import { Paper as MantinePaper, rem } from "@mantine/core";

const Paper: FC<any> = ({ children, noBg, ...props }) => {
  return (
    <MantinePaper
      sx={(theme) => ({
        backgroundColor: noBg ? "transparent" : theme.colors.dark[7],
        padding: rem(28),
        "& > *": {
          color: "#fff",
        },
        flex: 1,
        ...props?.sx,
      })}
      radius={8}
      {...props}
    >
      {children}
    </MantinePaper>
  );
};

export default Paper;
