import IERC20 from "./IERC20.json";
import uNFT from "./uNFT.json";
import v2NFT from "./v2NFT.json";

const abis = {
  PoPs: IERC20,
  IERC20: IERC20,
  uNFT: uNFT,
  v2NFT: v2NFT,
};

export default abis;
