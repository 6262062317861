import { Button as MantineButton } from "@mantine/core";
import useWeb3 from "shared/hooks/useWeb3";

const Button = ({ ...props }) => {
  const { handleConnect, handleDisconnect } = useWeb3();
  const onClickConnect = async () => {
    await handleConnect();
  };
  const onClickDisConnect = async () => {
    await handleDisconnect();
  };

  const { connected, chainId } = useWeb3();

  return (

    <div>
      {connected && chainId ? (
        <MantineButton
          onClick={async () => await onClickDisConnect()}
          sx={(theme) => ({
            backgroundColor: theme.colors.black[5],
            border: `1px solid ${theme.colors.zombie[5]}`,
            padding: "15px 30px",
            fontSize: "16px",
            height: "inherit",

            "&:hover": {
              backgroundColor: theme.colors.black[5],
            },

            [`@media screen and (max-width: ${theme.breakpoints.sm})`]: {
              display: "block",
              marginRight: "0",
              padding: "10px 14px",
              fontSize: "11px",
            },

            ...props?.sx,
          })}
          {...props}
        />
      ) : (
        <MantineButton
          onClick={async () => await onClickConnect()} 
          sx={(theme) => ({
            backgroundColor: theme.colors.black[5],
            border: `1px solid ${theme.colors.zombie[5]}`,
            padding: "15px 30px",
            fontSize: "16px",
            height: "inherit",

            "&:hover": {
              backgroundColor: theme.colors.black[5],
            },

            [`@media screen and (max-width: ${theme.breakpoints.sm})`]: {
              display: "block",
              marginRight: "0",
              padding: "10px 14px",
              fontSize: "11px",
            },

            ...props?.sx,
          })}
          {...props}
        />
      )}
    </div>

  );
}

export default Button;
