import { useState, useEffect } from "react";
import { Grid, Image, Title, Text, useMantineTheme } from "@mantine/core";
import useContracts from "../../shared/hooks/useContracts";
import Paper from "components/Paper";
import ZombieDrawer from "components/ZombieDrawer";
import useResponsive from "hooks/useResponsive";
import { useWeb3 } from "shared/hooks";
import Loader from "components/Loader";

const gridResponsive: any = {
  mobile: 12,
  tablet: 6,
  desktop: 3,
};

const MyZombies = () => {
  const { screen } = useResponsive();
  const theme = useMantineTheme();

  const [selectedZombie, setSelectedZombie] = useState<number | undefined>(
    undefined
  );
  const isDrawerOpen = typeof selectedZombie !== "undefined";

  const handleCloseDrawer = () => setSelectedZombie(undefined);
  const { getUserNFTsNew } = useContracts();

  const [userNfts, setUserNfts] = useState<Array<any> | null>();
  const [unftsLoaded, setUnftsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const { walletAddress, chainId, activeCollection } = useWeb3();

  const fetchUserUnfts = async () => {
    if (!getUserNFTsNew || !walletAddress) return;
    setLoading(true);
    const userNftTokenIds = await getUserNFTsNew(walletAddress);

    if (userNftTokenIds && userNftTokenIds.length > 0) {
      const result = userNftTokenIds; //userNftTokenIds.map((tokenId) => tokenId.toString());
      if (!result) return;
      const userNfts: Array<any> = result;
      setUserNfts(userNfts);
      setUnftsLoaded(true);
      setLoading(false);
    } else if (userNftTokenIds && userNftTokenIds.length === 0) {
      setUnftsLoaded(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chainId && walletAddress) {
      fetchUserUnfts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, walletAddress, unftsLoaded]);

  return (
    <>
      <Title color="zombie" align="center" my={40} size={60}>
        {activeCollection === "ZOMBIES" ? "My Zombies" : "My Soldiers"}
      </Title>
      {activeCollection === "ZOMBIES" ? (
        <Text align="center" weight={100} my={60}>
          Ice Cream Zombie Club! This unique NFT collection features 500
          one-of-a-kind Ice Cream Zombies, each with their own distinct zombie
          head as an ice cream cone design. As a proud owner of one of these
          exclusive NFTs, you'll gain access to the Ice Cream Zombie Club
          Discord, as well as a range of utility benefits, including double DAO
          voting power, access to exclusive launchpad events, and entry into
          monthly giveaways with exclusive prizes. Each Ice Cream Zombie NFT is
          a symbol of your commitment and engagement in the Ice Cream Zombie
          Club community.
        </Text>
      ) : (
        <Text align="center" weight={100} my={60}>
          Ice Cream Zombie Club! This unique NFT collection features 500
          one-of-a-kind Ice Cream Zombie Soldiers, each with their own distinct
          distinct zombie features. As a proud owner of one of these exclusive
          NFTs, you'll gain access to the Ice Cream Zombie Club Discord, as well
          as a range of utility benefits, including double DAO voting power,
          access to exclusive launchpad events, and entry into monthly giveaways
          with exclusive prizes. Each Ice Cream Zombie NFT is a symbol of your
          commitment and engagement in the Ice Cream Zombie Club community.
        </Text>
      )}
      {!walletAddress && !userNfts && (
        <Text color="white" align="center" size={17}>
          Connect your wallet to see brainzzzz
        </Text>
      )}
      {walletAddress && loading && !userNfts && (
        <Text color="white" align="center" size={17}>
          <Loader /> Loading your Zombies...
        </Text>
      )}
      {walletAddress && !userNfts && !loading && (
        <Text color="white" align="center" size={17}>
          You haven’t got any Zombies yet!
        </Text>
      )}

      <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
        {userNfts?.map((nft) => {
          return (
            <Grid.Col
              span={gridResponsive[screen] || 3}
              key={nft}
              onClick={() => setSelectedZombie(nft)}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Paper
                sx={{
                  "&:hover": {
                    backgroundColor: theme.colors.dark[7],
                    boxShadow: `0px 20px 60px ${theme.colors.zombie[5]}`,
                  },
                }}
              >
                {activeCollection === "ZOMBIES" ? (
                  <Image
                    src={`/images/NFT/compressed/${nft}.png`}
                    alt="front"
                    width="100%"
                  />
                ) : (
                  <Image
                    src={`/images/soldier/compressed/${nft}.jpg`}
                    alt="front"
                    width="100%"
                  />
                )}
              </Paper>
            </Grid.Col>
          );
        })}
      </Grid>
      <ZombieDrawer
        showUnstake
        opened={isDrawerOpen}
        nft={selectedZombie}
        close={handleCloseDrawer}
      />
    </>
  );
};

export default MyZombies;
