import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const useResponsive = () => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isDesktop = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);

  const screenType: any = {
    mobile: isMobile,
    tablet: isTablet,
    desktop: isDesktop,
  };

  return {
    isMobile,
    isTablet,
    isDesktop,
    screen: Object.keys(screenType).filter((key: any) => screenType[key])?.[0],
  };
};

export default useResponsive;
