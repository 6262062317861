import { FC, useEffect, useState } from "react";
import {
  Drawer,
  useMantineTheme,
  Image,
  Center,
  Title,
  Flex,
  Stack,
  Text,
  Grid,
  LoadingOverlay,
} from "@mantine/core";
import Loader from "components/Loader";
import Tabs, { TabComponents } from "components/Tabs";
import Paper from "components/Paper";
import Button from "components/Button";
import Input from "components/Input";
import Countdown from "components/Countdown";
import useContracts from "../../shared/hooks/useContracts";
import { BigNumber, utils } from "ethers";
import { toast } from "react-toastify";
import { useWeb3 } from "shared/hooks";
import { Link } from "react-router-dom";
import { rest, set } from "lodash";

export interface ZombieDrawerProps {
  nft: number | undefined;
  opened?: boolean;
  showUnstake?: boolean;
  close?: () => void;
}

const ZombieDrawer: FC<ZombieDrawerProps> = ({
  nft,
  opened = true,
  close = () => {},
}) => {
  const {
    myrewards,
    getWithdraw,
    getStake,
    restakeAmount,
    restakeEnabled,
    balanceOf,
    allowance,
    approve,
  } = useContracts();

  const [loading, setLoading] = useState(true);
  const [nftData, setNFTData] = useState<any>({});
  const [userReward, setUserReward] = useState(BigNumber.from(0));
  const [vestingEnd, setVestingEnd] = useState(0);
  const [restakeEnabledContract, setRestakeEnabledContract] = useState(false);
  const [showRestakeButton, setShowRestakeButton] = useState(false);
  const [restakeStakeAmount, setRestakeStakeAmount] = useState<any>();
  const [fetched, setFetched] = useState(false);
  const [userPoPsBalance, setUserPoPsBalance] = useState(BigNumber.from(0));
  const [allowed, setAllowed] = useState(BigNumber.from(0));
  const [approvalProcessing, setApprovalProcessing] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [done, setDone] = useState(false);
  const theme = useMantineTheme();
  const { chainId, activeCollection, wallet, connected, handleConnect } = useWeb3();
  const [showUnstake, setShowUnstake] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [approvalHash, setApprovalHash] = useState(null);
  const [withdrawProcessing, setWithdrawProcessing] = useState(false);
  const [stakeProcessing, setStakeProcessing] = useState(false);

  const getJSON = async () => {
    try {
      setLoading(true);
      const response =
        activeCollection === "ZOMBIES"
          ? await fetch(`/metadata/NFT/${nft}.json`)
          : await fetch(`/metadata/soldier/${nft}.json`);
      const data = await response.json();
      setNFTData(data);
    } catch (error) {
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJSON();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchData = async () => {
      if (myrewards && nft && nft !== undefined) {
        const mrr = await myrewards(nft);
        console.log("mrr", mrr);
        setUserReward(mrr ? mrr[0] : BigNumber.from(0));
        setVestingEnd(mrr ? Number(mrr[2]) : 0);

        if (restakeEnabled && restakeAmount) {
          const restakeShow = await restakeEnabled();
          const restakeRequiredAmount = await restakeAmount();

          setRestakeStakeAmount(
            restakeRequiredAmount ? restakeRequiredAmount : BigNumber.from(0)
          );
          setRestakeEnabledContract(restakeShow ? true : false);
        }
      }
    };

    if (vestingEnd > 0) {
      setFetched(true);
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nft, myrewards, fetched]);

  const getUpdates = async () => {
    try {
      //setAllowed(BigNumber.from(0))

      if (balanceOf) {
        const popsBalance = await balanceOf();
        setUserPoPsBalance(popsBalance ? popsBalance : BigNumber.from(0));
      }

      if (allowance) {
        const allowx = await allowance();
        setAllowed(allowx ? allowx : BigNumber.from(0));
      }

      /*     setFetching2(true) */
    } catch (e: any) {
      return null;
    }
  };

  useEffect(() => {
    const fetchBalance = async () => {
      setFetched(false);

      if (balanceOf) {
        const popsBalance = await balanceOf();
        setUserPoPsBalance(popsBalance ? popsBalance : BigNumber.from(0));
      }

      if (allowance) {
        const allowx = await allowance();
        setAllowed(allowx ? allowx : BigNumber.from(0));
      }

      if (!fetched && wallet) {
        getUpdates();
      }
    };
    setFetched(false);
    fetchBalance();
    setFetched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceOf, allowance, fetched, wallet]);

  useEffect(() => {
    if (
      restakeEnabledContract &&
      vestingEnd == 0 &&
      vestingEnd < Date.now() / 1000 &&
      userReward.isZero()
    ) {
      setShowRestakeButton(true);
    } else {
      setShowRestakeButton(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restakeEnabledContract]);

  useEffect(() => {
    connected ? setShowUnstake(true) : setShowUnstake(false);
  },[connected])


  const withdraw = async () => {
    try {
      if (getWithdraw) {
        setWithdrawProcessing(true);
        const result = await getWithdraw(nft);
        setApprovalHash(result.hash);
        await result.wait();
        toast.success("Unstaking Successful.");
        /*         if (result) {
                  setDone(true)
                }
                setDone(true) */
        setApprovalHash(null);
        setWithdrawProcessing(false);
        window.location.reload();
      }
    } catch (e: any) {
      toast.error("Transaction rejected by user");
      //window.location.reload();
      setApprovalHash(null);
      setWithdrawProcessing(false);
    }
  };

  const stake = async () => {
    try {
      if (getStake) {
        setStakeProcessing(true);
        const result = await getStake(nft);
        setApprovalHash(result.hash);
        await result.wait();
        toast.success("Restake Successful!");

        setApprovalHash(null);
        setStakeProcessing(false);
        window.location.reload();
      }
    } catch (e: any) {
      toast.error("Transaction rejected by user");
      //window.location.reload();
      setApprovalHash(null);
      setStakeProcessing(false);
      setApprovalProcessing(false);
    }
  };

  const getApprove = async () => {
    try {
      if (restakeStakeAmount && wallet && approve) {
        setApprovalProcessing(true);
        const result = await approve(restakeStakeAmount);
        setApprovalHash(result.hash);
        await result.wait();
        toast.success("Approval successful.");
        setApprovalHash(null);
        setApprovalProcessing(false);
        setFetched(false);
      }
    } catch (e: any) {
      toast.error("trasaction rejected by user");
      setApprovalHash(null);
      setApprovalProcessing(false);
      setFetched(true);
    }
  };

  return (
    <Drawer
      opened={opened}
      onClose={close}
      position="right"
      size={550}
      styles={{
        root: {
          overflow: "hidden",
        },
        body: {
          overflow: "hidden",
          // padding: 40,
        },
        content: {
          backgroundColor: theme.colors.dark[7],
          boxShadow: `0px 0px 8px ${theme.colors.zombie[5]}`,
        },
        inner: {
          padding: "0 !important",
        },
        header: {
          backgroundColor: theme.colors.dark[7],
        },
        close: {
          border: 0,
        },
      }}
    >
      <LoadingOverlay visible={loading} overlayColor="gray" />
      {!loading && (
        <Center>
          <Flex direction="column">
            <Center>
              {activeCollection === "ZOMBIES" ? (
                <Link
                  to={`https://bafybeietufotra656tb5bdhto2qhmltdo6gxfkn7id5rhytn4mn3wyjaq4.ipfs.nftstorage.link/${nft}.png`}
                >
                  <Image
                    src={`/images/NFT/compressed/${nft}.png`}
                    alt="front"
                    width="100%"
                    maw={350}
                  />
                </Link>
              ) : (
                <Link
                  to={`https://bafybeigtfvma3svom4oyv7g2imdlyx6oi5z5izylb2lrrsywdb4raktu4y.ipfs.nftstorage.link/${nft}.png`}
                >
                  <Image
                    src={`/images/soldier/compressed/${nft}.jpg`}
                    alt="front"
                    width="100%"
                    maw={350}
                  />
                </Link>
              )}
            </Center>
            <Title color="zombie" align="center" my={20}>
              {nftData?.name}
            </Title>
            {/*             <Text color="white" align="center" my={20}>
              {nftData?.description}
            </Text> */}
            <Tabs defaultValue="attributes">
              {showUnstake ? (
                <TabComponents.List my={20} sx={{ overflow: "visible" }}>
                  <TabComponents.Tab
                    value="attributes"
                    sx={{
                      "&[aria-selected=true]": {
                        boxShadow: `0px 0px 8px ${theme.colors.zombie[5]}`,
                        borderRadius: 60,
                      },
                    }}
                  >
                    ATTRIBUTES
                  </TabComponents.Tab>
                  <TabComponents.Tab
                    value="unstake"
                    sx={{
                      "&[aria-selected=true]": {
                        boxShadow: `0px 0px 8px ${theme.colors.zombie[5]}`,
                        borderRadius: 60,
                      },
                    }}
                  >
                    BALANCE
                  </TabComponents.Tab>
                </TabComponents.List>
              ) : (
                <TabComponents.List my={20} sx={{ overflow: "visible" }}>
                  <TabComponents.Tab
                    value="attributes"
                    sx={{
                      "&[aria-selected=true]": {
                        boxShadow: `0px 0px 8px ${theme.colors.zombie[5]}`,
                        borderRadius: 60,
                      },
                    }}
                  >
                    ATTRIBUTES
                  </TabComponents.Tab>
                  <TabComponents.Tab
                    onClick={handleConnect}
                    value="connect"
                    sx={{
                      "&[aria-selected=true]": {
                        boxShadow: `0px 0px 8px ${theme.colors.zombie[5]}`,
                        borderRadius: 60,
                      },
                    }}
                  >
                    BALANCE
                  </TabComponents.Tab>
                </TabComponents.List>
              )}
              <TabComponents.Panel value="attributes" pt="xs" maw={450}>
                <Center>
                  <Grid gutter={24}>
                    {nftData?.attributes?.map((attribute: any) => (
                      <Grid.Col span={6}>
                        <Paper
                          sx={{
                            backgroundColor: theme.colors.gray[8],
                          }}
                          p={20}
                          width="auto"
                        >
                          <Text color="white" size={18}>
                            {attribute?.trait_type}
                          </Text>
                          <Text color="white" weight={100} size={14}>
                            {attribute?.value}
                          </Text>
                        </Paper>
                      </Grid.Col>
                    ))}
                  </Grid>
                </Center>
              </TabComponents.Panel>
              <TabComponents.Panel value="unstake" pt="xs">
                <Center>
                  <Paper p={20}>
                    <Stack>
                      <Flex
                        direction="column"
                        justify="center"
                        align="center"
                        style={{ height: "100%" }}
                      >
                        <Center>
                          <Flex
                            direction="column"
                            justify="center"
                            align="center"
                          >
                            <Stack spacing={24}>
                              <Flex
                                align="center"
                                pb={12}
                                style={{ borderBottom: "1px solid" }}
                              >
                                <Text color="gray">Staked Balance</Text>
                                <Flex ml="auto" align="center">
                                  <Input
                                    readOnly
                                    value={Number(
                                      utils.formatUnits(
                                        userReward?.toString(),
                                        18
                                      )
                                    ).toFixed(2)}
                                    styles={{
                                      input: {
                                        textAlign: "right",
                                        color: "#fff",
                                        fontSize: "16px",
                                      },
                                    }}
                                    pr={5}
                                  />
                                  <Text color="white" size={17}>
                                    {"SLUSH"}
                                  </Text>
                                </Flex>
                              </Flex>

                              {vestingEnd > 0 && userReward.gt(0) && (
                                <Text color="gray" size={14}>
                                  {" "}
                                  <Countdown
                                    title="Unstake countdown"
                                    endDate={vestingEnd * 1000}
                                  >
                                    <Button
                                      disabled={
                                        withdrawProcessing ||
                                        Number(
                                          utils.formatUnits(
                                            userReward?.toString()
                                          )
                                        ) === 0
                                      }
                                      mt={12}
                                      onClick={withdraw}
                                    >
                                      Unstake
                                    </Button>
                                  </Countdown>
                                </Text>
                              )}

                              {showRestakeButton && (
                                <>
                                  <Title color="zombie" align="center" my={20}>
                                    Restake
                                  </Title>
                                  <Flex
                                    align="center"
                                    pb={12}
                                    style={{ borderBottom: "1px solid" }}
                                  >
                                    <Text color="gray">Restake Amount</Text>
                                    <Flex ml="auto" align="center">
                                      <Input
                                        readOnly
                                        value={Number(
                                          utils.formatUnits(
                                            restakeStakeAmount?.toString(),
                                            18
                                          )
                                        ).toFixed(2)}
                                        styles={{
                                          input: {
                                            textAlign: "right",
                                            color: "#fff",
                                            fontSize: "16px",
                                          },
                                        }}
                                        pr={5}
                                      />
                                      <Text color="white" size={17}>
                                        {"SLUSH"}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </>
                              )}

                              {fetched === false || approvalProcessing || stakeProcessing ? (
                                <Button disabled>
                                  <Text color="grey" size={14}>
                                    <Loader /> Loading
                                  </Text>
                                </Button>
                              ) : showRestakeButton &&
                                userPoPsBalance.lt(restakeStakeAmount) ? (
                                <Button disabled>{"Insufficient SLUSH"}</Button>
                              ) : showRestakeButton ? (
                                <Text color="gray" size={14}>
                                  {" "}
                                    <Button
                                      disabled={stakeProcessing || approvalProcessing}
                                      mt={12}
                                      onClick={
                                        !allowed.lt(restakeStakeAmount)
                                          ? stake
                                          : getApprove
                                      }
                                    >
                                     {!allowed.lt(restakeStakeAmount)
                                      ? "Restake"
                                      : "Approve " +
                                        Number(
                                          utils.formatUnits(
                                            restakeStakeAmount.toString(),
                                            18
                                          )
                                        ).toFixed(2)}
                                    </Button>
                                </Text>
                              ) : (
                                <></>
                              )}
                            </Stack>
                          </Flex>
                        </Center>
                      </Flex>
                    </Stack>
                  </Paper>{" "}
                </Center>
              </TabComponents.Panel>
            </Tabs>
          </Flex>
        </Center>
      )}
    </Drawer>
  );
};

export default ZombieDrawer;
