import { Tabs as MantineTabs, useMantineTheme } from "@mantine/core";

const Tabs = (props: any) => {
  const theme = useMantineTheme();

  return (
    <MantineTabs
      variant="pills"
      color="gray"
      styles={{
        tabsList: {
          backgroundColor: theme.colors.dark[7],
          borderRadius: "35px",
          overflow: "hidden",
        },
        tabLabel: { color: theme.white },
        tab: {
          flex: 1,
          padding: "14px",
          [`&[data-active]`]: {
            backgroundColor: theme.colors.dark[5] + " !important",
            borderRadius: "35px",
          },
          [`&:active`]: {
            backgroundColor: theme.colors.dark[5],
            borderRadius: "35px",
          },
          [`&:hover`]: {
            backgroundColor: theme.colors.dark[7],
            borderRadius: "35px",
          },
        },
      }}
      {...props}
    />
  );
};

export default Tabs;
