import { useState, useEffect } from "react";

import {
  Center,
  Title,
  Text,
  Stack,
  Flex,
  Image,
  Container,
  LoadingOverlay,
} from "@mantine/core";
import { Cube } from "react-motion-components";

import PageContainer from "layout/PageContainer/PageContainer";
import Paper from "components/Paper";
import Input from "components/Input";
import Button from "components/Button";
import Tabs, { TabComponents } from "components/Tabs";
import NFTRevealModal from "components/NFTRevealModal";
import useMint from "hooks/useMint";
import useResponsive from "hooks/useResponsive";
import Logo from "assets/images/logo.png";
import useContracts from "../../shared/hooks/useContracts";
import { BigNumber, utils } from "ethers";
import { useWeb3 } from "shared/hooks";
import { toast } from "react-toastify";
import Loader from "components/Loader";

const Mint = () => {
  const { isTablet } = useResponsive();
  const { nft, loading, mint, removeNft } = useMint();
  const [globalLoading, setGlobalLoading] = useState(true);
  const [cubeSx, setCubeSx] = useState<any>({
    [`& > div > div > div`]: {
      transform:
        "translate3d(0px, 0px, -125px) rotateX(338deg) rotateY(492deg) rotateZ(0deg) !important",
    },
  });

  const { handleConnect } = useWeb3();
  const onClickConnect = async () => {
    await handleConnect();
  };

  const {
    balanceOf,
    mintingEnabled,
    mintCount,
    stakerequired,
    approve,
    allowance,
    minting,
  } = useContracts();
  const [userPoPsBalance, setUserPoPsBalance] = useState(BigNumber.from(0));
  const [ismintingEnabledX, setIsMintingEnabledX] = useState(false);
  const [minted, setMinted] = useState(BigNumber.from(0));
  const [srequired, setSRequired] = useState(BigNumber.from(0));

  const [allowed, setAllowed] = useState(BigNumber.from(0));
  const [fetching, setFetching] = useState(true);
  const [approvalHash, setApprovalHash] = useState(null);
  const [approvalProcessing, setApprovalProcessing] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetching2, setFetching2] = useState(false);
  const [mintProcessing, setMintProcessing] = useState(false);

  const { wallet, walletAddress, chainId, activeCollection } = useWeb3();

  const getUpdates = async () => {
    try {
      //setAllowed(BigNumber.from(0))

      if (balanceOf) {
        const popsBalance = await balanceOf();
        setUserPoPsBalance(popsBalance ? popsBalance : BigNumber.from(0));
      }

      if (allowance) {
        const allowx = await allowance();
        setAllowed(allowx ? allowx : BigNumber.from(0));
      }
      setFetching(false);
      /*     setFetching2(true) */
    } catch (e: any) {
      return null;
    }
  };

  useEffect(() => {
    setCubeSx({
      [`& > div > div > div`]: {
        transform:
          "translate3d(0px, 0px, -125px) rotateX(338deg) rotateY(492deg) rotateZ(0deg) !important",
      },
    });
  }, [activeCollection]);

  useEffect(() => {
    const fetchValues = async () => {
      if (mintCount) {
        const isMinted = await mintCount();
        setGlobalLoading(false);
        setMinted(isMinted ? isMinted : BigNumber.from(0));
      }
    };
    fetchValues();
  }, [mintCount]);

  useEffect(() => {
    const fetchBalance = async () => {
      if (stakerequired) {
        const srequiredx = await stakerequired();
        setSRequired(srequiredx ? srequiredx : BigNumber.from(0));
      }

      if (mintingEnabled) {
        const minting = await mintingEnabled();
        setIsMintingEnabledX(minting ? true : false);
      }

      if (balanceOf) {
        const popsBalance = await balanceOf();
        setUserPoPsBalance(popsBalance ? popsBalance : BigNumber.from(0));
      }

      if (allowance) {
        const allowx = await allowance();
        setAllowed(allowx ? allowx : BigNumber.from(0));
      }

      if (fetching && wallet) {
        getUpdates();
      }

      /*       if (fetching2 && wallet) {
              if (allowed.gte(srequired)) {
                setFetching2(false)
              } else {
                setFetching2(false)
              }
            } */
    };
    fetchBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceOf, mintingEnabled, stakerequired, allowance, fetching, wallet]);

  const getApprove = async () => {
    try {
      if (stakerequired && wallet && approve) {
        setApprovalProcessing(true);
        const result = await approve(srequired);
        setApprovalHash(result.hash);
        await result.wait();
        toast.success("Approval successful.");
        setApprovalHash(null);
        setApprovalProcessing(false);
        setFetching(true);
      }
    } catch (e: any) {
      toast.error("trasaction rejected by user");
      setApprovalHash(null);
      setApprovalProcessing(false);
      setFetching(false);
    }
  };

  const getMint = async () => {
    try {
      if (minting && wallet && stakerequired) {
        setMintProcessing(true);
        const result = await minting();
        setApprovalHash(result.hash);

        await result.wait();
        await mint();

        toast.success("Mint and Stake successful.");

        setMintProcessing(false);
        setApprovalHash(null);
        setFetching(true);
      }
    } catch (e: any) {
      toast.error("trasaction rejected by user");
      setApprovalHash(null);
      setApprovalProcessing(false);
      setFetching(false);
    }
  };

  return (
    <>
      <PageContainer mb={100}>
        <Container size="lg">
          <Center>
            <Stack spacing={40} maw="99%">
              <Center>
                <Image src={Logo} alt="Ice Cream Zombies" maw={500} />
              </Center>
              {activeCollection === "ZOMBIES" ? (
                <Text align="center" weight={100}>
                  Ice Cream Zombies, this release will consist of 500 unique Ice
                  Cream Zombie NFTs. Each NFT will offer access to the utility
                  benefits listed on the homepage, as well as entry into the Ice
                  Cream Zombie Club Discord community. Ice Cream Zombie NFTs can
                  be minted by staking the required amount of SLUSH tokens for a
                  12-month period. Ice Cream Zombie NFTs staking requirement
                  starts at 180 SLUSH and increasing by 2% after every 10 NFTs
                  are minted.
                </Text>
              ) : (
                <Text align="center" weight={100}>
                  Ice Cream Zombie Soldiers, this release will consist of 500
                  unique Ice Cream Zombie Soldier NFTs on Mantle. Each NFT will
                  offer access to the utility benefits listed on the homepage,
                  as well as entry into the Ice Cream Zombie Club Discord
                  community. Ice Cream Zombie NFTs can be minted by staking the
                  required amount of SLUSH tokens for a 12-month period. Ice
                  Cream Zombie NFTs staking requirement starts at 180 SLUSH and
                  increasing by 2% after every 10 NFTs are minted.
                </Text>
              )}

              <div style={{ position: "relative", paddingBottom: "20px" }}>
                <LoadingOverlay
                  visible={globalLoading}
                  overlayColor="#0f0f11"
                  overlayOpacity={1}
                  style={{ zIndex: 0 }}
                />
                <Title align="center" order={2} color="zombie" size={40}>
                  {!minted || minted.toNumber() === 0 || minted.toNumber()
                    ? 500 - minted.toNumber()
                    : 0}{" "}
                  Available!
                </Title>
                <Tabs defaultValue="mint">
                  <Flex gap={24} wrap="wrap">
                    <Flex direction="column" style={{ flex: 1 }}>
                      <TabComponents.Panel value="mint" pt="xs">
                        <Center>
                          <Paper maw={450}>
                            <Stack>
                              <Center>
                                <Stack spacing={2}>
                                  <Title
                                    order={2}
                                    color="zombie"
                                    size={36}
                                    align="center"
                                  >
                                    {activeCollection === "ZOMBIES"
                                      ? "Zombie Mint"
                                      : "Soldier Mint"}
                                  </Title>
                                  <Text size="xs" align="center">
                                    {minted.toString()} NFT's Minted
                                  </Text>
                                </Stack>
                              </Center>
                              <Stack spacing={24} py={15}>
                                <Flex
                                  align="center"
                                  pb={12}
                                  style={{ borderBottom: "1px solid" }}
                                >
                                  <Text color="gray">Balance</Text>
                                  <Flex ml="auto" align="center">
                                    <Input
                                      readOnly
                                      value={Number(
                                        Number(
                                          utils.formatUnits(
                                            userPoPsBalance.toString(),
                                            18
                                          )
                                        ).toFixed(2)
                                      ).toLocaleString()}
                                      styles={{
                                        input: {
                                          textAlign: "right",
                                          color: "#fff",
                                          fontSize: "16px",
                                        },
                                      }}
                                      pr={5}
                                    />
                                    <Text color="white" size={17}>
                                      {"SLUSH"}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex
                                  align="center"
                                  pb={12}
                                  style={{ borderBottom: "1px solid" }}
                                >
                                  <Text color="gray">SLUSH Required</Text>
                                  <Flex ml="auto" align="center">
                                    <Input
                                      readOnly
                                      value={
                                        srequired.toString() !== "0"
                                          ? Number(
                                              Number(
                                                utils.formatUnits(
                                                  srequired.toString(),
                                                  18
                                                )
                                              ).toFixed(2)
                                            ).toLocaleString()
                                          : "180"
                                      }
                                      styles={{
                                        input: {
                                          textAlign: "right",
                                          color: "#fff",
                                          fontSize: "16px",
                                        },
                                      }}
                                      pr={5}
                                    />
                                    <Text color="white" size={17}>
                                      {"SLUSH"}
                                    </Text>
                                  </Flex>
                                </Flex>

                                {!chainId && !walletAddress ? (
                                  <Button
                                    onClick={async () => await onClickConnect()}
                                  >
                                    {"Connect Wallet"}
                                  </Button>
                                ) : fetching === true ? (
                                  <Button disabled>
                                    <Text color="grey" size={14}>
                                      <Loader /> Loading
                                    </Text>
                                  </Button>
                                ) : userPoPsBalance.lt(srequired) ? (
                                  <Button disabled>
                                    {"Insufficient SLUSH"}
                                  </Button>
                                ) : !ismintingEnabledX ? (
                                  <Button disabled>{"Minting Paused"}</Button>
                                ) : (
                                  <Button
                                    disabled={
                                      !ismintingEnabledX ||
                                      approvalProcessing === true ||
                                      mintProcessing === true
                                    }
                                    mt={12}
                                    loading={loading}
                                    onClick={
                                      !allowed.lt(srequired)
                                        ? getMint
                                        : getApprove
                                    }
                                  >
                                    {!allowed.lt(srequired)
                                      ? "Mint & Stake"
                                      : "Approve " +
                                        Number(
                                          utils.formatUnits(
                                            srequired.toString(),
                                            18
                                          )
                                        ).toFixed(2)}
                                  </Button>
                                )}
                                {approvalHash && (
                                  <div>
                                    {/*                                   <a
                                    href={`${process.env.REACT_APP_ETHERSCAN_TX}${approvalHash}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    color="white"
                                  >
                                    <Text color="white" size={17}>
                                      {approvalProcessing && <>Waiting for confirmation.</>}
                                      {!approvalProcessing && <>Your last transaction.</>}
                                    </Text>
                                  </a> */}
                                    {/* {mintProcessing && (
                                    <Text color="white" size={17}>
                                      {(Number(utils.formatUnits(srequired.toString(), 18)).toFixed(2))} {' '} POPS deposited
                                      in the contract & vested (locked) for 365 days
                                    </Text>
                                  )} */}
                                  </div>
                                )}
                              </Stack>
                            </Stack>
                          </Paper>
                        </Center>
                      </TabComponents.Panel>
                      <TabComponents.Panel value="stake" pt="xs">
                        <Paper>
                          <Stack>
                            <Center>
                              <Stack spacing={2}>
                                <Title
                                  order={2}
                                  color="zombie"
                                  size={36}
                                  align="center"
                                >
                                  UNSTAKE
                                </Title>
                              </Stack>
                            </Center>
                            <Stack spacing={24}>
                              <Flex
                                align="center"
                                pb={12}
                                style={{ borderBottom: "1px solid" }}
                              >
                                <Text color="gray">Staked Balance</Text>
                                <Flex ml="auto" align="center">
                                  <Input
                                    readOnly
                                    value="0.00"
                                    styles={{
                                      input: {
                                        textAlign: "right",
                                        color: "#fff",
                                        fontSize: "16px",
                                      },
                                    }}
                                    pr={5}
                                  />
                                  <Text color="white" size={17}>
                                    {"SLUSH"}
                                  </Text>
                                </Flex>
                              </Flex>
                              <Button mt={12} disabled>
                                Unstake
                              </Button>
                            </Stack>
                          </Stack>
                        </Paper>
                      </TabComponents.Panel>
                    </Flex>
                    <>
                      {!isTablet && (
                        <TabComponents.Panel
                          value="mint"
                          pt="xs"
                          style={{ flex: 1 }}
                        >
                          <Paper noBg>
                            <Flex
                              justify="center"
                              align="center"
                              style={{ height: "100%" }}
                              sx={cubeSx}
                              onClick={() => setCubeSx(null)}
                            >
                              <div
                                style={{
                                  width: 280,
                                  height: 280,
                                }}
                              >
                                {activeCollection === "ZOMBIES" ? (
                                  <>
                                    <div></div>
                                    {/* @ts-ignore */}
                                    <Cube size={280} index="bottom">
                                      <img
                                        src={"/images/NFT/compressed/0.png"}
                                        alt="front"
                                      />
                                      <img
                                        src={"/images/NFT/compressed/2.png"}
                                        alt="right"
                                      />
                                      <img
                                        src={"/images/NFT/compressed/3.png"}
                                        alt="back"
                                      />
                                      <img
                                        src={"/images/NFT/compressed/100.png"}
                                        alt="left"
                                      />
                                      <img
                                        src={"/images/NFT/compressed/5.png"}
                                        alt="top"
                                      />
                                      <img
                                        src={"/images/NFT/compressed/6.png"}
                                        alt="bottom"
                                      />
                                    </Cube>
                                  </>
                                ) : (
                                  <>
                                    {/* @ts-ignore */}
                                    <Cube size={280} index="bottom">
                                      <img
                                        src={
                                          "/images/soldier/compressed/20.jpg"
                                        }
                                        alt="front"
                                      />
                                      <img
                                        src={
                                          "/images/soldier/compressed/222.jpg"
                                        }
                                        alt="right"
                                      />
                                      <img
                                        src={"/images/soldier/compressed/3.jpg"}
                                        alt="back"
                                      />
                                      <img
                                        src={
                                          "/images/soldier/compressed/483.jpg"
                                        }
                                        alt="left"
                                      />
                                      <img
                                        src={"/images/soldier/compressed/5.jpg"}
                                        alt="top"
                                      />
                                      <img
                                        src={
                                          "/images/soldier/compressed/321.jpg"
                                        }
                                        alt="bottom"
                                      />
                                    </Cube>
                                  </>
                                )}
                              </div>
                            </Flex>
                          </Paper>
                        </TabComponents.Panel>
                      )}
                    </>
                  </Flex>
                </Tabs>
              </div>
            </Stack>
          </Center>
        </Container>
      </PageContainer>
      {nft && <NFTRevealModal nft={nft} close={removeNft} />}
    </>
  );
};

export default Mint;
