import { BigNumber } from 'ethers';
import { createContext} from 'react';

interface IContractContext {
  balanceOf: () => Promise<BigNumber | null>;
  mintingEnabled: () => Promise<boolean | null>;
  mintCount: () => Promise<BigNumber | null>;
  stakerequired: () => Promise<BigNumber | null>;
  restakeEnabled: () => Promise<boolean | null>;
  restakeAmount: () => Promise<BigNumber | null>;
  approve: (
    amount: BigNumber,
  ) => Promise<any>;
  allowance: () => Promise<BigNumber | null>;
  minting: () => Promise<any>;
  supply: () => Promise<any>;
  //getUserNFTs: (walletAddress: string) => Promise<undefined | Array<BigNumber>>;
  myrewards: (nft:any) => Promise<any | null>;
  getWithdraw: (nft:any) => Promise<any | null>;
  getStake: (nft:any) => Promise<any | null>;
  getUserNFTsNew:(walletAddress: string) => Promise<any | null>;
  fetchSnapshot: () => Promise<any | null>;
}

const Context = createContext<IContractContext | null>(null);

export default Context;
