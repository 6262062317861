import { FC } from "react";
import { default as ReactCountdown } from "react-countdown";
import { Flex, Text, Stack, Center, Title } from "@mantine/core";

const Countdown: FC<any> = ({ children, endDate, small = true, title }) => {
  const renderer = ({ completed, formatted }: any) => {
    const { days, hours, minutes, seconds } = formatted;

    if (completed) {
      return children;
    } else {
      return (
        <Flex direction="column" gap={40}>
          {title && (
            <Title color="zombie" order={2} align="center" mt={20} mb={-40}>
              {title}
            </Title>
          )}
          <Center>
            <Flex gap={small ? 18 : 36} my={40}>
              <Stack spacing={0.5}>
                <Text align="center" size={small ? 18 : 36}>
                  {days}
                </Text>
                <Text align="center">DAYS</Text>
              </Stack>
              <Stack spacing={0.5}>
                <Text align="center" size={small ? 18 : 36}>
                  {hours}
                </Text>
                <Text align="center">HOURS</Text>
              </Stack>
              <Stack spacing={0.5}>
                <Text align="center" size={small ? 18 : 36}>
                  {minutes}
                </Text>
                <Text align="center">MINUTES</Text>
              </Stack>
              <Stack spacing={0.5}>
                <Text align="center" size={small ? 18 : 36}>
                  {seconds}
                </Text>
                <Text align="center">SECONDS</Text>
              </Stack>
            </Flex>
          </Center>
        </Flex>
      );
    }
  };

  return <ReactCountdown date={endDate} renderer={renderer} />;
};

export default Countdown;
