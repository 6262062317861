export const generateRandomNumbers = (start: number, end: number, length: number) => {
  const numberArray = Array.from({ length: end - start + 1 }, (_, i) => start + i);
  const randomNumbers: number[] = [];

  while (randomNumbers.length < length) {
    const randomIndex = Math.floor(Math.random() * numberArray.length);
    const randomNumber = numberArray[randomIndex];
    if (!randomNumbers.includes(randomNumber)) {
      randomNumbers.push(randomNumber);
    }
  }

  return randomNumbers;
}