import { useState } from "react";
import useContracts from '../shared/hooks/useContracts';
import { useWeb3 } from 'shared/hooks';


export const mintNft: (nft: any) => Promise<number> = async (nft) => {
  return new Promise(resolve => setTimeout(() => {
    resolve(nft + 1);
  }, 1000));
}

const useMint = () => {
  const [loading, setLoading] = useState(false);
  const [nft, setNft] = useState<number | undefined>(undefined);
  const { getUserNFTsNew } = useContracts()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nftDummy, setNftDummy ] = useState<number | undefined>(undefined);

  const {
    walletAddress,
  } = useWeb3();

  const mint = async () => {
    setLoading(true);
    try {
      if (getUserNFTsNew && walletAddress) {

        const userNftTokenIds = await getUserNFTsNew(walletAddress);
        if (userNftTokenIds && userNftTokenIds.length > 0) {
          const result = userNftTokenIds
          if (!result) return;
          const userNfts: Array<any> = result;
          setNft(userNfts[userNfts.length - 1]);
          const nftDummyx = await mintNft(nft);
          setNftDummy(nftDummyx);
        } else if (userNftTokenIds && userNftTokenIds.length === 0) {
          setNft(0);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }
  const removeNft = () => setNft(undefined);

  return {
    nft,
    removeNft,
    loading,
    mint
  }
}

export default useMint;