import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import { MantineProvider } from "@mantine/core";
import { QueryClientProvider } from "@tanstack/react-query";

import App from "./App";
import PoppinsFont from "layout/Fonts/Poppins";
import BloodyFont from "layout/Fonts/Bloody";
import reportWebVitals from "./reportWebVitals";
import queryClient from "services/client";

import defaultTheme from "themes/default";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <MantineProvider theme={defaultTheme}>
          <BloodyFont />
          <PoppinsFont />
          <App />
        </MantineProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
