import { Input as MantineInput, useMantineTheme } from "@mantine/core";

const Input = (props: any) => {
  const theme = useMantineTheme();
  return (
    <MantineInput
      variant="unstyled"
      styles={{ input: { color: theme.white } }}
      {...props}
    />
  );
};

export default Input;
