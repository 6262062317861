import { useEffect, useState } from "react";
import { Contract, BigNumber, providers } from "ethers";
import { JsonRpcSigner } from "@ethersproject/providers";
import Context from "./Context";
import abis from "../../abis";
import useWeb3 from "../../hooks/useWeb3";
import addresses from "../../addresses";

const uNFT = new Contract(
  addresses.uNFT,
  abis.uNFT,
  new providers.JsonRpcProvider(addresses.rpcURL)
);
const uNFT2 = new Contract(
  addresses.uNFTT,
  abis.v2NFT,
  new providers.JsonRpcProvider(addresses.rpcURLT)
);

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { wallet, walletAddress, chainId, activeCollection } = useWeb3();
  const [PoPs, setPoPs] = useState(new Contract(addresses.PoPs, abis.PoPs));
  const [PoPsT, setPoPsT] = useState(new Contract(addresses.PoPsT, abis.PoPs));

  useEffect(() => {
    const init = async () => {
      if (!!wallet) {
        if (!PoPs.signer) {
          setPoPs(PoPs.connect(wallet));
        }
        if (!PoPsT.signer) {
          setPoPsT(PoPsT.connect(wallet));
        }
      }
    };

    init();
  }, [wallet, PoPs, PoPsT]);

  const balanceOf = async () => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const PoPsType = chainId === "0x28" ? PoPs : PoPsT;

    if (!walletAddress) return null;
    if (chainId !== networkID) return null;

    try {
      const balance = await PoPsType.balanceOf(walletAddress);
      return balance as BigNumber;
    } catch (err) {
      return null;
    }
  };

  const mintingEnabled = async () => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress) return null;
    if (chainId !== networkID) return null;

    try {
      const isEligible = await UseuNFT.mintingEnabled();

      return isEligible;
    } catch (err) {
      return null;
    }
  };

  const mintCount = async () => {
    //const networkID = chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = activeCollection === "ZOMBIES" ? uNFT : uNFT2;

    //if (!walletAddress) return null;
    //if (chainId !== networkID) return null;

    try {
      const mints = await UseuNFT.mintCount();
      return mints as BigNumber;
    } catch (err) {
      return null;
    }
  };

  const stakerequired = async () => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress) return null;
    if (chainId !== networkID) return null;

    try {
      const requi = await UseuNFT.stakeRequired();
      return requi as BigNumber;
    } catch (err) {
      return null;
    }
  };

  const approve = async (amount: BigNumber) => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const PoPsType = chainId === "0x28" ? PoPs : PoPsT;
    const amnt = chainId === "0x28" ? addresses.uNFT : addresses.uNFTT;

    if (!walletAddress || !PoPsType.signer || !wallet) return null;
    if (chainId !== networkID) return null;

    try {
      const PoPsWithSigner = PoPsType.connect(wallet as JsonRpcSigner);
      return await PoPsWithSigner.approve(amnt, amount);
    } catch (err) {
      return null;
    }
  };

  const allowance = async () => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const PoPsType = chainId === "0x28" ? PoPs : PoPsT;
    const amnt = chainId === "0x28" ? addresses.uNFT : addresses.uNFTT;

    if (!walletAddress || !PoPsType.signer) return null;
    if (chainId !== networkID) return null;

    try {
      const amount = await PoPsType.allowance(walletAddress, amnt);
      return amount;
    } catch (err) {
      return null;
    }
  };

  const minting = async () => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress || !wallet) return null;
    if (chainId !== networkID) return null;

    try {
      const UnftWithSigner = UseuNFT.connect(wallet as JsonRpcSigner);
      return await UnftWithSigner.mint();
    } catch (err) {
      return null;
    }
  };

  const supply = async () => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress) return null;
    if (chainId !== networkID) return null;

    try {
      const xsupply = await UseuNFT.totalSupply();
      return xsupply;
    } catch (err) {
      return null;
    }
  };

  /*   const getUserNFTs = async (walletAddress: string) => {
    const networkID = chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;
    if (!walletAddress) return null;
    if (chainId !== networkID) return null;

    const balance = await UseuNFT.balanceOf(walletAddress);

    const tokenIds = [];
    for (let i = 0; i < balance; i++) {
      const tokenId = await UseuNFT.tokenOfOwnerByIndex(walletAddress, i);
      tokenIds.push(tokenId);
    }
    return tokenIds;
  }; */

  const getUserNFTsNew = async (walletAddress: string) => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress) return null;
    if (chainId !== networkID) return null;

    try {
      const tokenIds: number[] = [];
      const tokenId = await UseuNFT.nftsOf(walletAddress);

      tokenId.forEach((nft: { tokenId: string }) => {
        tokenIds.push(parseInt(nft.tokenId));
      });

      return tokenIds;
    } catch (err) {
      return null;
    }
  };

  const fetchSnapshot = async () => {
    //const networkID = chainId === "0x29" ? addresses.networkIDT : addresses.networkID;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    /*     if (!walletAddress) return null;
    if (chainId !== networkID) return null; */

    try {
      const tokenIds = [];
      const tokenId = await UseuNFT.snapshot();

      for (let i = 0; i < tokenId.length; i++) {
        tokenIds.push(tokenId[i][1]);
      }
      return tokenIds;
    } catch (err) {
      return null;
    }
  };

  const myrewards = async (nft: number) => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress) return null;
    if (chainId !== networkID) return null;

    try {
      const rbalance = await UseuNFT.tokenStatus(nft);
      return rbalance as any;
    } catch (err) {
      return null;
    }
  };

  const restakeEnabled = async () => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress) return null;
    if (chainId !== networkID) return null;

    try {
      const stakeEnabled = await UseuNFT.stakingEnabled();
      return stakeEnabled as any;
    } catch (err) {
      return null;
    }
  };

  const restakeAmount = async () => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress) return null;
    if (chainId !== networkID) return null;

    try {
      const stakeEnabled = await UseuNFT.newStakeRequired();
      return stakeEnabled as any;
    } catch (err) {
      return null;
    }
  };

  const getWithdraw = async (nft: number) => {
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress || !wallet) return null;
    if (chainId !== networkID) return null;

    try {
      const UnftWithSigner = UseuNFT.connect(wallet as JsonRpcSigner);
      const wholeNumber = await UnftWithSigner.unstake(nft);
      return wholeNumber;
    } catch (err) {
      return null;
    }
  };

  const getStake = async (nft: number) => {
    console.log('getStake', nft)
    const networkID =
      chainId === "0x28" ? addresses.networkID : addresses.networkIDT;
    const UseuNFT = chainId === "0x28" ? uNFT : uNFT2;

    if (!walletAddress || !wallet) return null;
    if (chainId !== networkID) return null;

    try {
      const UnftWithSigner = UseuNFT.connect(wallet as JsonRpcSigner);
      const wholeNumber = await UnftWithSigner.stake(nft);
      return wholeNumber;
    } catch (err) {
      console.error(err)
      return null;
    }
  };
  

  return (
    <Context.Provider
      value={{
        balanceOf,
        mintingEnabled,
        mintCount,
        stakerequired,
        approve,
        allowance,
        minting,
        supply,
        restakeAmount,
        restakeEnabled,
        /*      getUserNFTs, */
        myrewards,
        getWithdraw,
        getStake,
        getUserNFTsNew,
        fetchSnapshot,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
