const addresses = {
  PoPs: process.env.REACT_APP_POPS_ADDRESS!,
  uNFT: process.env.REACT_APP_UNFT_ADDRESS!,
  networkID: process.env.REACT_APP_NETWORK_ID!,
  networkName: process.env.REACT_APP_NETWORK_NAME!,
  rpcURL: process.env.REACT_APP_TELOS_RPC_URL!,
  mainnetRpcURL: process.env.REACT_APP_TELOS_NETWORK_NAME!,

  PoPsT: process.env.REACT_APP_POPS_ADDRESST!,
  uNFTT: process.env.REACT_APP_UNFT_ADDRESST!,
  networkIDT: process.env.REACT_APP_NETWORK_IDT!,
  networkNameT: process.env.REACT_APP_NETWORK_NAMET!,
  rpcURLT: process.env.REACT_APP_TELOS_RPC_URLT!,
  mainnetRpcURLT: process.env.REACT_APP_TELOS_NETWORK_NAMET!,

};

export default addresses;
