import { useState } from "react";
import { Outlet } from "react-router-dom";
import { AppShell, Container, Navbar, Flex, MediaQuery } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";

import Header from "layout/Header/Header";
import Button from "components/Button";

const Root = () => {
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    setOpened(false);
    navigate(path);
  };

  return (
    <AppShell
      padding="md"
      header={<Header openMenu={setOpened} menuOpened={opened} />}
      styles={{
        main: {
          paddingLeft: "calc(var(--mantine-aside-width, 0px) + 1rem)",
        },
      }}
      navbar={
        <MediaQuery
          largerThan="xs"
          styles={{ display: opened ? "inherit" : "none" }}
        >
          <Navbar
            p="md"
            hidden={!opened}
            width={{ sm: 200, lg: 300 }}
            sx={{
              background: "#0f0f11",
              top: "50px",
              left: "1px",
            }}
          >
            <Flex gap={24} direction="column">
              <Button fullWidth onClick={() => handleNavigate("/mint")}>
                MINT
              </Button>
              <Button fullWidth onClick={() => handleNavigate("/zombieland")}>
                ZOMBIELAND
              </Button>
              <Button fullWidth onClick={() => handleNavigate("/my-zombies")}>
                MY ZOMBIES
              </Button>
            </Flex>
          </Navbar>
        </MediaQuery>
      }
    >
      <Container size={1385} p="xs" mt={50}>
        <Outlet />
      </Container>
      <Notifications />
    </AppShell>
  );
};

export default Root;
