import { FC, useState, useEffect, useMemo } from "react";
import { Modal, Flex, Text, Title, useMantineTheme } from "@mantine/core";
import { Carousel } from "react-motion-components";
import Confetti from "react-confetti";

import Button from "components/Button";
import useResponsive from "hooks/useResponsive";
import { generateRandomNumbers } from "utils/random";

import { useWeb3 } from "shared/hooks";

export interface NFTRevealModalProps {
  opened?: boolean;
  nft?: number | undefined;
  close?: () => void;
}

const itemStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 30,
  fontWeight: "bold",
  color: "white",
};

const indexSpeeds: any = [
  ...Array.from({ length: 10 }, (x: number) => 300),
  ...Array.from({ length: 5 }, (x: number) => 500),
  ...Array.from({ length: 5 }, (x: number) => 700),
  ...Array.from({ length: 3 }, (x: number) => 900),
];

const NFTRevealModal: FC<NFTRevealModalProps> = ({
  nft,
  opened = true,
  close = () => {},
}) => {
  const [index, setIndex] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [nftName, setNftName] = useState(null);
  const theme = useMantineTheme();
  const { isTablet } = useResponsive();
  const { chainId } = useWeb3();

  const defaultStyle = {
    width: isTablet ? 200 : 300,
    height: isTablet ? 200 : 300,
    margin: "0 auto",
    overflow: "hidden",
  };

  useEffect(() => {
    if (!nft) {
      return;
    }
    const updateIndex = () => setIndex(index + 1);
    let timeout =
      indexSpeeds[index] && setTimeout(updateIndex, indexSpeeds[index]);

    if (!timeout) {
      timeout = setTimeout(() => {
        setIsFinished(true);
      }, 1500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [index, nft]);

  useEffect(() => {
    if (isFinished && nft !== null) {
      const nftMetaData =
        chainId === "0x28"
          ? `/metadata/NFT/${nft}.json`
          : `/metadata/soldier/${nft}.json`;

      fetch(nftMetaData)
        .then((response) => response.json())
        .then((data) => setNftName(data.name))
        .catch((error) => console.error("Error fetching NFT metadata:", error));
    }
  }, [chainId, isFinished, nft]);

  // useEffect(() => {
  //   const canvasElements = document.getElementsByTagName("canvas");
  //   if (canvasElements && canvasElements.length) {
  //     canvasElements[0].remove();
  //   }
  // }, [isFinished]);

  const randomNFTs = useMemo(
    () => [...generateRandomNumbers(0, 499, 23), nft], // TODO: random position here
    [nft]
  );

  return (
    <>
      <Modal
        opened={opened}
        centered
        size={isTablet ? "xs" : "md"}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
        onClose={close}
        overlayProps={{
          blur: 4,
        }}
        styles={{
          root: {
            overflow: "hidden",
          },
          body: {
            overflow: "hidden",
          },
          content: {
            backgroundColor: theme.colors.dark[7],
            boxShadow: `0px 20px 60px ${theme.colors.zombie[5]}`,
          },
          inner: {
            padding: "0 !important",
          },
          header: {
            backgroundColor: theme.colors.dark[7],
          },
        }}
      >
        <Flex my={20} gap={40} direction="column" justify="center">
          <Title align="center" order={2} color="zombie" size={40}>
            {isFinished ? "Congratulations" : "Minting..."}
          </Title>
          <Text align="center" color="zombie" size="lg">
            {isFinished
              ? nftName
                ? `You Minted: ${nftName}`
                : "Your Zombie NFT: " + nft?.toString()
              : "Spawning your Zombie..."}
          </Text>
          <div
            style={{
              ...defaultStyle,
            }}
          >
            {!isFinished && (
              <Carousel
                {...defaultStyle}
                direction={"horizontal"}
                effect="3d"
                index={index}
                onClick={() => {}}
                onChange={(index) => {}}
              >
                {randomNFTs.map((number: number | undefined) => {
                  return (
                    <div
                      key={number}
                      style={{
                        ...defaultStyle,
                        ...itemStyle,
                      }}
                    >
                      <img
                        src={
                          chainId === "0x28"
                            ? `/images/NFT/compressed/${number}.png`
                            : `/images/soldier/compressed/${number}.jpg`
                        }
                        alt="front"
                        width="100%"
                      />
                    </div>
                  );
                })}
              </Carousel>
            )}
            {isFinished && (
              <img
                src={
                  chainId === "0x28"
                    ? `/images/NFT/compressed/${nft}.png`
                    : `/images/soldier/compressed/${nft}.jpg`
                }
                alt="front"
                width="100%"
              />
            )}
          </div>
          {!isFinished && (
            <Text
              color="zombie"
              align="center"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => setIsFinished(true)}
            >
              Skip ?
            </Text>
          )}
          {isFinished && (
            <div
              style={{
                position: "absolute",
                overflow: "hidden",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
              }}
            >
              <Confetti />
            </div>
          )}
          {isFinished && (
            <Button mx={40} onClick={close}>
              Close
            </Button>
          )}
        </Flex>
      </Modal>
    </>
  );
};

export default NFTRevealModal;
