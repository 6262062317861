import { Global } from "@mantine/core";

const BloodyFont = () => {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "Bloody",
            src: `url('/fonts/BloodyOffice.woff2') format('woff2')`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
      ]}
    />
  );
};

export default BloodyFont;
