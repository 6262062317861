import { useEffect, useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Context from "./Context";
import addresses from "shared/addresses";

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [web3Modal, setWeb3Modal] = useState<Web3Modal>();
  const [installed, setInstalled] = useState(false);
  const [connected, setConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string>();
  const [walletBalance, setWalletBalance] = useState<string>();
  const [chainId, setChainId] = useState<string>("");
  const [activeCollection, setActiveCollection] = useState<string>("zombies");
  const [wallet, setWallet] = useState<ethers.providers.JsonRpcSigner>();
  const [currentProvider, setCurrentProvider] = useState<any>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loginState, setLoginState] = useState(false);

  const handleConnect = async () => {
    if (!web3Modal) return;
    setConnecting(true);

    try {
      let provider = await web3Modal?.connect();

      if (provider) {
        const newWeb3 = new ethers.providers.Web3Provider(provider, "any");
        const accounts = await newWeb3.listAccounts();
        const balance = await newWeb3.getBalance(accounts[0]);

        setWalletBalance(ethers.utils.formatEther(balance));
        setWalletAddress(accounts[0]);
        setWallet(newWeb3.getSigner());
        setConnected(true);

        setChainId((newWeb3.provider as any).chainId);
        setCurrentProvider(provider);
        setLoading(false);

        if (window.localStorage)
          window.localStorage.setItem("wallet_connect", "true");
      } else {
        await handleDisconnect();
      }
    } catch (e) {
      console.log(e);
    }
    setConnecting(false);
  };

  const switchNetwork = async (newChainId: string) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: newChainId }],
      });
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError?.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              newChainId === addresses.networkID
                ? {
                    chainId: newChainId,
                    chainName: addresses.networkName,
                    rpcUrls: [addresses.rpcURL],
                  }
                : {
                    chainId: newChainId,
                    chainName: addresses.networkNameT,
                    rpcUrls: [addresses.rpcURLT],
                  },
            ],
          });
        } catch (addError) {
          // handle "add" error
          console.log("addError:", addError);
        }
      }
      // handle other "switch" errors
    }
  };

  const handleDisconnect = async () => {
    setConnected(false);
    setWalletAddress(undefined);
    setWallet(undefined);
    setCurrentProvider(null);
    if (web3Modal) {
      web3Modal.clearCachedProvider();
    }
    if (window.localStorage) {
      window.localStorage.setItem("wallet_connect", "false");
    }
  };

  const getSetWalletBalance = async () => {
    if (wallet) {
      let balance: any = await wallet.getBalance();
      balance = ethers.utils.formatEther(balance);
      setWalletBalance(balance);
      return balance;
    }
  };
  const checkTransaction = async (hash: string) => {
    if (currentProvider) {
      const newWeb3 = new ethers.providers.Web3Provider(currentProvider, "any");
      return await newWeb3.perform("getTransactionReceipt", {
        transactionHash: hash,
      });
    }
    return null;
  };

  const waitForTransaction = (hash: string, timeOut = 1000) => {
    return new Promise((resolve, reject) => {
      if (hash === null || hash === undefined) {
        reject();
        return;
      }
      const interval = setInterval(async () => {
        const result = await checkTransaction(hash);
        if (result) {
          if (result.status === "0x1" || result.status === 1) {
            resolve(true);
          } else {
            reject(false);
          }

          clearInterval(interval);
        }
      }, timeOut);
    });
  };

  const trySwitchNetwork = async () => {
    //await switchNetwork(addresses.networkID);
    await handleConnect();
  };

  useEffect(() => {
    if (web3Modal === undefined) return;
    if (typeof window.ethereum !== "undefined") {
      setInstalled(true);
      if (connected) {
        handleConnect();
      } else {
        setLoading(false);
      }
    } else {
      setInstalled(false);
      setLoading(false);
    }
  }, [web3Modal]); // eslint-disable-line

  useEffect(() => {
    if (window.localStorage)
      setConnected(localStorage.getItem("wallet_connect") === "true");

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            [addresses.networkID]: addresses.rpcURL,
            [addresses.networkIDT]: addresses.rpcURLT,
          },
          network: "mainnet",
        },
      },
    };

    const web3Modal = new Web3Modal({
      cacheProvider: true,
      theme: {
        background: "rgb(15, 15, 17)",
        main: "rgb(199, 199, 199)",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)",
      },
      providerOptions,
    });

    setWeb3Modal(web3Modal);
  }, []);

  useEffect(() => {
    if (!currentProvider) return;

    currentProvider.on(
      "accountsChanged",
      async ([newAddress]: Array<string>) => {
        if (newAddress) {
          setWalletAddress(ethers.utils.getAddress(newAddress));
          window.location.reload();
        } else {
          await handleDisconnect();
        }
      }
    );

    currentProvider.on("chainChanged", (chain: number) => {
      if (chain === parseInt(addresses.networkID, 16)) return;
      setChainId(chain.toString());
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProvider]);

  useEffect(() => {
    if (chainId) {
      if (chainId === addresses.networkID || chainId === addresses.networkIDT) {
      } else {
        trySwitchNetwork();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return (
    <Context.Provider
      value={{
        handleConnect,
        handleDisconnect,
        switchNetwork,
        loading,
        installed,
        connected,
        connecting,
        walletAddress,
        walletBalance,
        wallet,
        chainId,
        checkTransaction,
        waitForTransaction,
        getSetWalletBalance,
        loginState,
        activeCollection,
        setActiveCollection,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
