import {
  Header as MantineHeader,
  Group,
  Flex,
  Container,
  Anchor,
  MediaQuery,
  Burger,
  NativeSelect,
  useMantineTheme,
} from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { useWeb3 } from "shared/hooks";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaTwitter, FaDiscord, FaTelegram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import Button from "components/Button";
import { simplifiedWalletAddress } from "helpers/utils";
import { useEffect } from "react";

const hideStyle = { display: "none" };

const Header = ({ menuOpened, openMenu }: any) => {
  const theme = useMantineTheme();
  const [scroll] = useWindowScroll();
  const navigate = useNavigate();
  const {
    connected,
    chainId,
    walletAddress,
    switchNetwork,
    activeCollection,
    setActiveCollection,
  } = useWeb3();

  // function to handle the change event
  const handleChangeNftCollectionDropdown = async (_collection: string) => {
    if (_collection === "ZOMBIES") {
      try {
        setActiveCollection("ZOMBIES");
        if (connected && chainId !== "0x28") {
          switchNetwork("0x28");
        }
      } catch (e) {
        console.error("Error while switching network", e);
      }
    }

    if (_collection === "SOLDIERS") {
      try {
        setActiveCollection("SOLDIERS");
        if (connected && chainId !== "0x1388") {
          switchNetwork("0x1388");
        } 
      } catch (e) {
        console.error("Error while switching network", e);
      }
    }
  };

  useEffect(() => {
    if (chainId === "0x1388") {
      setActiveCollection("SOLDIERS");
    } else {
      setActiveCollection("ZOMBIES");
    }
  }, [chainId, connected, setActiveCollection]);

  return (
    <MantineHeader
      height="auto"
      p="xs"
      pt={10}
      style={{
        background: "#0f0f11",
        border: 0,
        borderBottom:
          scroll?.y > 0 && !menuOpened
            ? `1px solid ${theme.colors.zombie[5]}`
            : 0,
      }}
    >
      <Container size={1385}>
        <Group position="apart" align="center">
          <Flex gap={12}>
            <MediaQuery largerThan="sm" styles={hideStyle}>
              <Burger
                opened={menuOpened}
                onClick={() => openMenu((o: boolean) => !o)}
                size="sm"
                color={theme.colors.zombie[5]}
                mr="xl"
              />
            </MediaQuery>
            <MediaQuery smallerThan="sm" styles={hideStyle}>
              <Flex gap={12}>
                <Button onClick={() => navigate("/mint")}>MINT</Button>
                <Button onClick={() => navigate("/zombieland")}>
                  ZOMBIELAND
                </Button>
                <Button onClick={() => navigate("/my-zombies")}>
                  MY ZOMBIES
                </Button>
              </Flex>
            </MediaQuery>
          </Flex>
          <Flex gap={12} justify="center" align="center">
            <MediaQuery smallerThan="sm" styles={hideStyle}>
              <Flex gap={24}>
                <Anchor
                  href="https://t.me/SwapsicleDAO"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Flex>
                    <FaTelegram size={20} />
                  </Flex>
                </Anchor>
                <Anchor
                  href="https://discord.gg/swapsicle"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Flex>
                    <FaDiscord size={20} />
                  </Flex>
                </Anchor>
                <Anchor
                  href="https://twitter.com/SwapsicleDEX"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Flex>
                    <FaTwitter size={20} />
                  </Flex>
                </Anchor>
              </Flex>
            </MediaQuery>

            <MediaQuery smallerThan="md" styles={hideStyle}>
              <NativeSelect
                styles={{
                  input: {
                    height: "inherit",
                    backgroundColor: theme.colors.black[5],
                    border: `1px solid ${theme.colors.zombie[5]}`,
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "white",
                    padding: "7px 30px 7px 16px",
                    "&:hover": {
                      backgroundColor: theme.colors.black[5],
                    },
                  },
                }}
                value={activeCollection}
                onChange={(event) =>
                  handleChangeNftCollectionDropdown(event.currentTarget.value)
                }
                data={["ZOMBIES", "SOLDIERS"]}
              />
            </MediaQuery>
            <MediaQuery largerThan="sm" styles={hideStyle}>
              <NativeSelect
                styles={{
                  input: {
                    backgroundColor: theme.colors.black[5],
                    border: `1px solid ${theme.colors.zombie[5]}`,
                    display: "block",
                    fontSize: "11px",
                    color: "white",
                    fontWeight: 600,
                  },
                }}
                value={activeCollection}
                onChange={(event) =>
                  handleChangeNftCollectionDropdown(event.currentTarget.value)
                }
                data={["ZOMBIES", "SOLDIERS"]}
              />
            </MediaQuery>

            {connected && chainId ? (
              <Button ml={10}>{simplifiedWalletAddress(walletAddress)}</Button>
            ) : (
              <Button ml={10}>Connect</Button>
            )}
          </Flex>
        </Group>
      </Container>
    </MantineHeader>
  );
};

export default Header;
