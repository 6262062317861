import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Web3Provider } from "./shared/context/Web3";
import router from "pages/router";
import { ContractsProvider } from "./shared/context/Contracts";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async ([newAddress]: Array<string>) => {
        if (newAddress) return;
        window.localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
        window.localStorage.removeItem("wallet_connect");
      });
    }
  }, []);


  return (
    <div>

      <Web3Provider>
        <ContractsProvider>
          <RouterProvider router={router} />
        </ContractsProvider>
      </Web3Provider>

      <ToastContainer
        theme="dark"
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default App;
