import { Flex } from "@mantine/core";

interface PageContainerProps {
  redirectPath?: string;
  children?: JSX.Element | JSX.Element[];
  [s: string]: any;
}

const PageContainer = ({ children, ...props }: PageContainerProps) => {
  return (
    <Flex
      style={{ position: "relative" }}
      direction="column"
      mt={20}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default PageContainer;
