import { createBrowserRouter, Outlet } from "react-router-dom";

// PAGES
import Root from "./Root";
import ErrorPage from "./ErrorPage";
import Mint from "pages/Mint";
import Zombieland from "pages/Zombieland";
import MyZombies from "pages/MyZombies";

const mainRoutes = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Mint />,
      },
      {
        path: "mint",
        element: <Mint />,
      },
      {
        path: "zombieland",
        element: <Zombieland />,
      },
      {
        path: "zombieland/:id",
        element: <Zombieland />,
      },
      {
        path: "my-zombies",
        element: <MyZombies />,
      },
    ],
  },
];

export const routes = [
  {
    path: "/",
    breadcrumb: "Home",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: mainRoutes,
  },
];

const router = createBrowserRouter(routes);

export default router;
