import chroma from "chroma-js";

const colors = {
  primary: "#10ff3f",
  black: "#0e0f15",
};

const generateColorShades = (color: string): any => {
  const baseColor = chroma(color);
  const shades = chroma
    .scale([baseColor.brighten(1.5), color, baseColor.darken(1.5)])
    .mode("lch")
    .colors(10);
  return shades;
};

const theme = {
  fontFamily: "Poppins, sans-serif",
  headings: { fontFamily: "Bloody, sans-serif" },
  colors: {
    zombie: generateColorShades(colors.primary),
    black: generateColorShades(colors.black),
  },
  primaryColor: "zombie",
  breakpoints: {
    xs: "30em",
    sm: "52em",
    md: "64em",
    lg: "74em",
    xl: "90em",
  },
};

export default theme;
