import { ethers, BigNumber } from "ethers";

export const datetime2str = (date: Date | string | number | undefined): string => {
  if (date === undefined) return "";
  const cTime = new Date(date);

  return (
    cTime.getFullYear() +
    "-" +
    ("0" + (cTime.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + cTime.getDate()).slice(-2) +
    " " +
    ("0" + cTime.getHours()).slice(-2) +
    "-" +
    ("0" + cTime.getMinutes()).slice(-2) +
    "-" +
    ("0" + cTime.getSeconds()).slice(-2)
  );
};

export const date2str = (date: Date | string | number | undefined): string => {
  if (date === undefined) return "";
  const cTime = new Date(date);

  return cTime.getFullYear() + "-" + ("0" + (cTime.getMonth() + 1)).slice(-2) + "-" + ("0" + cTime.getDate()).slice(-2);
};

export const time2str = (date: Date | string | number | undefined): string => {
  if (date === undefined) return "";
  const cTime = new Date(date);

  return (
    ("0" + cTime.getHours()).slice(-2) +
    "-" +
    ("0" + cTime.getMinutes()).slice(-2) +
    "-" +
    ("0" + cTime.getSeconds()).slice(-2)
  );
};

export const commaValue = (val: string | number | undefined, decimal?: number): string => {
  if (val === undefined) return "";

  let num = typeof val === "string" ? parseFloat(val) : val;

  var parts = num.toFixed(decimal).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const str2number = (val: string | undefined): number => {
  if (val == null) return 0;

  let num = Number(val);

  if (num == null) return 0;
  return num;
};

export const simplifiedWalletAddress = (_address: any) => {
  if (!_address) return "";
  const address = _address.toString();
  return address.slice(0, 6) + "..." + address.slice(-4);
};

export const formatUnits = (
  val: BigNumber | number,
  decimal: number, // number of decimal
  commify: boolean = true,
  round: boolean = false
): string => {
  let amount = ethers.utils.formatUnits(val, decimal);
  let commified = "";
  if (commify) {
    commified = ethers.utils.commify(amount);
  } else {
    commified = amount;
  }

  let parts = commified.split(".");
  let integerPart = parts[0];
  if (parts.length === 1 || round) {
    return integerPart;
  }
  let decimalPart = parts[1];
  if (decimalPart === "0") {
    return integerPart;
  } else {
    return commified;
  }
};

export const parseCommified = (val: string): number => {
  if (!val) return 0;
  val = val.replaceAll(",", "");
  let result = parseFloat(val);
  return result;
};

export const toFixedNoRounding = function (num: number, digits: number = 2) {
  var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
    m = num.toString().match(re);
  return m ? parseFloat(m[1]) : num.valueOf();
};

export const isValidContractAddress = (value: string) => {
  if (!value) {
    return { isValid: false, validationError: "No Address Provided" };
  }
  const isValidAddress = ethers.utils.isAddress(value);
  return { isValid: isValidAddress, validationError: "Invalid Address" };
};


export const isWholeNumber = (value: string) => {
  if (value) {
    return Number.isInteger(Number(value));
  }
  return false;
};

export const isValidTokenName = (value: string, maxLen: number) => {
  if (!value) return { isValid: false, validationError: "No Token Provided" };
  return { isValid: value.length <= maxLen, validationError: `Token should be less than ${maxLen} symbols` };
};

export const intToString = (num: number) => {
  num = parseFloat(num.toString());
  if (Math.abs(num) < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: "K" },
    { v: 1e6, s: "M" },
    { v: 1e9, s: "B" },
    { v: 1e12, s: "T" },
    { v: 1e15, s: "P" },
    { v: 1e18, s: "E" }
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (Math.abs(num) >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
};

export const Capitalize = (str: string) => {
  if (str)
    return str.charAt(0).toUpperCase() + str.slice(1);
  else return ''
}