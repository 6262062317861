import { useState, useEffect } from "react";
import { Grid, Image, Title, useMantineTheme, Text } from "@mantine/core";
import { useParams, useNavigate } from "react-router-dom";
import useContracts from "../../shared/hooks/useContracts";

import Paper from "components/Paper";
import ZombieDrawer from "components/ZombieDrawer";
import useResponsive from "hooks/useResponsive";
import { useWeb3 } from "shared/hooks";

const gridResponsive: any = {
  mobile: 12,
  tablet: 6,
  desktop: 3,
};

const Zombieland = () => {
  const { screen } = useResponsive();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  let { id } = useParams();
  const { chainId, activeCollection } = useWeb3();

  const [selectedZombie, setSelectedZombie] = useState<number | undefined>(
    id ? Number(id) : undefined
  );
  const isDrawerOpen = typeof selectedZombie !== "undefined";

  const handleSelectZombie = (zombie: number) => {
    navigate(`/zombieland/${zombie}`);
    setSelectedZombie(zombie);
  };

  const handleCloseDrawer = () => {
    setSelectedZombie(undefined);
    navigate("/zombieland");
  };

  const { fetchSnapshot } = useContracts();

  const [userNfts, setUserNfts] = useState<Array<any> | null>();
  const [unftsLoaded, setUnftsLoaded] = useState(false);

  const fetchSnapshots = async () => {
    if (!fetchSnapshot) return;
    const snapshot = await fetchSnapshot();

    if (snapshot && snapshot.length > 0) {
      const result = snapshot;
      if (!result) return;
      const userNfts: Array<any> = result;
      setUserNfts(userNfts);
      setUnftsLoaded(true);
    } else if (snapshot && snapshot.length === 0) {
      setUnftsLoaded(true);
    }
  };

  useEffect(() => {
    fetchSnapshots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unftsLoaded]);

  return (
    <>
      <Title color="zombie" align="center" my={40} size={60}>
        Zombieland
      </Title>
      {activeCollection === "ZOMBIES" ? (
        <Text align="center" weight={100} my={60}>
          Ice Cream Zombie Club! This unique NFT collection features 500
          one-of-a-kind Ice Cream Zombies, each with their own distinct zombie
          head as an ice cream cone design. As a proud owner of one of these
          exclusive NFTs, you'll gain access to the Ice Cream Zombie Club
          Discord, as well as a range of utility benefits, including double DAO
          voting power, access to exclusive launchpad events, and entry into
          monthly giveaways with exclusive prizes. Each Ice Cream Zombie NFT is
          a symbol of your commitment and engagement in the Ice Cream Zombie
          Club community.
        </Text>
      ) : (
        <Text align="center" weight={100} my={60}>
          Ice Cream Zombie Club! This unique NFT collection features 500
          one-of-a-kind Ice Cream Soldiers, each with their own distinct zombie
          head as an ice cream cone design. As a proud owner of one of these
          exclusive NFTs, you'll gain access to the Ice Cream Zombie Club
          Discord, as well as a range of utility benefits, including double DAO
          voting power, access to exclusive launchpad events, and entry into
          monthly giveaways with exclusive prizes. Each Ice Cream Zombie NFT is
          a symbol of your commitment and engagement in the Ice Cream Zombie
          Club community.
        </Text>
      )}

      <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
        {userNfts?.map((i) => {
          return (
            <Grid.Col
              span={gridResponsive[screen] || 3}
              key={i}
              onClick={() => handleSelectZombie(i)}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Paper
                sx={{
                  "&:hover": {
                    backgroundColor: theme.colors.dark[7],
                    boxShadow: `0px 20px 60px ${theme.colors.zombie[5]}`,
                  },
                }}
              >
                {activeCollection === "ZOMBIES" ? (
                  <Image
                    src={`/images/NFT/compressed/${i}.png`}
                    alt="front"
                    width="100%"
                  />
                ) : (
                  <Image
                    src={`/images/soldier/compressed/${i}.jpg`}
                    alt="front"
                    width="100%"
                  />
                )}
              </Paper>
            </Grid.Col>
          );
        })}
      </Grid>
      <ZombieDrawer
        opened={isDrawerOpen}
        nft={selectedZombie}
        close={handleCloseDrawer}
      />
    </>
  );
};

export default Zombieland;
