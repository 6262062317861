import { Global } from "@mantine/core";

const PoppinsFont = () => {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: "url('https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2') format('woff2')",
            fontWeight: 100,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: "url('https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2') format('woff2')",
            fontWeight: 200,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: "url('https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2') format('woff2')",
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: "url('https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2') format('woff2')",
            fontWeight: 500,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins",
            src: "url('https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2') format('woff2')",
            fontWeight: 700,
            fontStyle: "normal",
          },
        },

      ]}
    />
  );
};

export default PoppinsFont;
